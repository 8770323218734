//en
import React from 'react'
import classnames from 'classnames'
import styles from './index.module.scss'
import { LogoIcon } from '../icons'
import { Trans } from '@lingui/macro'

function Footer({ color = '#fff', className = '', noBorderTop = true }) {
  return (
    <div className={styles.footerWrapper}>
      <div className={classnames(styles.footer, className)} style={noBorderTop ? { borderTop: 0 } : null}>
        <div className={styles.contact}>
          <div>
            <LogoIcon color={color} />
          </div>
        </div>
        <div className={styles.email}>
          <div>
            <Trans>
              <span>For questions and concerns, email us at </span>
              <a href="mailto:chat@rns.id">chat@rns.id</a>
            </Trans>
          </div>
          <div className={styles.socialLink}>
            <a href="https://www.youtube.com/@rns_id/featured" target="_blank" rel="noreferrer">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M31.7609 15.2C31.7609 15.2 31.5266 13.5454 30.8047 12.8188C29.8906 11.8625 28.8688 11.8579 28.4 11.8016C25.0438 11.5579 20.0047 11.5579 20.0047 11.5579H19.9953C19.9953 11.5579 14.9563 11.5579 11.6 11.8016C11.1313 11.8579 10.1094 11.8625 9.19531 12.8188C8.47344 13.5454 8.24375 15.2 8.24375 15.2C8.24375 15.2 8 17.1454 8 19.086V20.9047C8 22.8454 8.23906 24.7907 8.23906 24.7907C8.23906 24.7907 8.47344 26.4454 9.19062 27.1719C10.1047 28.1282 11.3047 28.0954 11.8391 28.1985C13.7609 28.3813 20 28.4375 20 28.4375C20 28.4375 25.0438 28.4282 28.4 28.1891C28.8688 28.1329 29.8906 28.1282 30.8047 27.1719C31.5266 26.4454 31.7609 24.7907 31.7609 24.7907C31.7609 24.7907 32 22.85 32 20.9047V19.086C32 17.1454 31.7609 15.2 31.7609 15.2ZM17.5203 23.1125V16.3672L24.0031 19.7516L17.5203 23.1125Z"
                  fill="#A5A5A5"
                />
              </svg>
            </a>
            <a className={styles.twitterLogo} href="https://twitter.com/RNS_global" target="_blank" rel="noreferrer">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M26.3262 9.90393H29.6996L22.3296 18.3274L30.9999 29.7899H24.2111L18.8939 22.838L12.8098 29.7899H9.43431L17.3173 20.78L8.99988 9.90393H15.961L20.7673 16.2583L26.3262 9.90393ZM25.1422 27.7707H27.0115L14.9453 11.8171H12.9393L25.1422 27.7707Z"
                  fill="#A5A5A5"
                />
              </svg>
            </a>
            <a className={styles.discordLogo} href="https://discord.gg/rns" target="_blank" rel="noreferrer">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M28.317 12.1556C26.7873 11.4537 25.147 10.9366 23.4319 10.6404C23.4007 10.6347 23.3695 10.649 23.3534 10.6775C23.1424 11.0528 22.9087 11.5423 22.7451 11.927C20.9004 11.6508 19.0652 11.6508 17.2583 11.927C17.0946 11.5337 16.8525 11.0528 16.6406 10.6775C16.6245 10.6499 16.5933 10.6356 16.562 10.6404C14.8479 10.9356 13.2076 11.4527 11.6769 12.1556C11.6637 12.1613 11.6523 12.1708 11.6448 12.1832C8.53339 16.8316 7.68105 21.3657 8.09918 25.8435C8.10107 25.8655 8.11337 25.8864 8.1304 25.8997C10.1832 27.4073 12.1717 28.3225 14.1233 28.9291C14.1545 28.9386 14.1876 28.9272 14.2075 28.9015C14.6691 28.2711 15.0806 27.6063 15.4335 26.9073C15.4543 26.8664 15.4344 26.8178 15.3919 26.8016C14.7391 26.554 14.1176 26.2521 13.5197 25.9093C13.4724 25.8816 13.4687 25.814 13.5122 25.7816C13.638 25.6873 13.7638 25.5893 13.884 25.4902C13.9057 25.4721 13.936 25.4683 13.9615 25.4797C17.8893 27.273 22.1415 27.273 26.023 25.4797C26.0485 25.4674 26.0788 25.4712 26.1015 25.4893C26.2216 25.5883 26.3475 25.6873 26.4742 25.7816C26.5177 25.814 26.5149 25.8816 26.4676 25.9093C25.8697 26.2587 25.2482 26.554 24.5945 26.8006C24.552 26.8168 24.533 26.8664 24.5538 26.9073C24.9143 27.6054 25.3258 28.2701 25.7789 28.9005C25.7978 28.9272 25.8319 28.9386 25.8631 28.9291C27.8241 28.3225 29.8126 27.4073 31.8654 25.8997C31.8834 25.8864 31.8948 25.8664 31.8967 25.8445C32.3971 20.6676 31.0585 16.1706 28.3482 12.1841C28.3416 12.1708 28.3303 12.1613 28.317 12.1556ZM16.02 23.117C14.8375 23.117 13.8631 22.0313 13.8631 20.6981C13.8631 19.3648 14.8186 18.2791 16.02 18.2791C17.2309 18.2791 18.1958 19.3743 18.1769 20.6981C18.1769 22.0313 17.2214 23.117 16.02 23.117ZM23.9947 23.117C22.8123 23.117 21.8379 22.0313 21.8379 20.6981C21.8379 19.3648 22.7933 18.2791 23.9947 18.2791C25.2056 18.2791 26.1705 19.3743 26.1516 20.6981C26.1516 22.0313 25.2056 23.117 23.9947 23.117Z"
                  fill="#A5A5A5"
                />
              </svg>
            </a>
            <a
              className={styles.facebookLogo}
              href="https://www.facebook.com/rnsweb3id?mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer"
            >
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3440_1399)">
                  <path
                    d="M20 8C13.3726 8 8 13.3726 8 20C8 25.6275 11.8746 30.3498 17.1013 31.6467V23.6672H14.6269V20H17.1013V18.4198C17.1013 14.3355 18.9498 12.4424 22.9597 12.4424C23.72 12.4424 25.0318 12.5917 25.5685 12.7405V16.0645C25.2853 16.0347 24.7933 16.0198 24.1822 16.0198C22.2147 16.0198 21.4544 16.7653 21.4544 18.703V20H25.3741L24.7006 23.6672H21.4544V31.9122C27.3963 31.1946 32.0005 26.1354 32.0005 20C32 13.3726 26.6274 8 20 8Z"
                    fill="#A5A5A5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3440_1399">
                    <rect width="24" height="24" fill="white" transform="translate(8 8)" />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a className={styles.facebookLogo} href="https://t.me/+a11E4WpsTzIzYmQx" target="_blank" rel="noreferrer">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_830_1484)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32 20C32 26.6274 26.6274 32 20 32C13.3726 32 8 26.6274 8 20C8 13.3726 13.3726 8 20 8C26.6274 8 32 13.3726 32 20ZM20.43 16.8589C19.2628 17.3444 16.9301 18.3492 13.4319 19.8733C12.8638 20.0992 12.5663 20.3202 12.5392 20.5363C12.4934 20.9015 12.9507 21.0453 13.5735 21.2411C13.6582 21.2678 13.746 21.2954 13.8359 21.3246C14.4486 21.5238 15.2728 21.7568 15.7013 21.766C16.0899 21.7744 16.5237 21.6142 17.0026 21.2853C20.2712 19.079 21.9584 17.9638 22.0643 17.9398C22.139 17.9228 22.2426 17.9015 22.3128 17.9638C22.3829 18.0262 22.376 18.1443 22.3686 18.176C22.3233 18.3691 20.5281 20.0381 19.5991 20.9018C19.3095 21.171 19.1041 21.362 19.0621 21.4056C18.968 21.5033 18.8721 21.5958 18.78 21.6846C18.2108 22.2333 17.7839 22.6448 18.8036 23.3168C19.2936 23.6397 19.6858 23.9067 20.077 24.1731C20.5042 24.4641 20.9303 24.7543 21.4816 25.1157C21.6221 25.2077 21.7562 25.3034 21.8869 25.3965C22.3841 25.751 22.8307 26.0694 23.3826 26.0186C23.7032 25.9891 24.0345 25.6876 24.2027 24.7884C24.6002 22.6631 25.3816 18.0585 25.5622 16.161C25.578 15.9947 25.5581 15.782 25.5422 15.6886C25.5262 15.5952 25.4928 15.4621 25.3714 15.3636C25.2276 15.2469 25.0056 15.2223 24.9064 15.2241C24.455 15.232 23.7626 15.4728 20.43 16.8589Z"
                    fill="#A5A5A5"
                    style={{ fill: '#A5A5A5', fillOpacity: 1 }}
                  />
                </g>
                <defs>
                  <clipPath id="clip0_830_1484">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      style={{ fill: 'white', fillOpacity: 1 }}
                      transform="translate(8 8)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a className={styles.facebookLogo} href="https://wa.me/6807704211" target="_blank" rel="noreferrer">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_831_1487)">
                  <path
                    d="M8 32L9.687 25.837C8.646 24.033 8.099 21.988 8.1 19.891C8.103 13.335 13.438 8 19.993 8C23.174 8.001 26.16 9.24 28.406 11.488C30.651 13.736 31.887 16.724 31.886 19.902C31.883 26.459 26.548 31.794 19.993 31.794C18.003 31.793 16.042 31.294 14.305 30.346L8 32ZM14.597 28.193C16.273 29.188 17.873 29.784 19.989 29.785C25.437 29.785 29.875 25.351 29.878 19.9C29.88 14.438 25.463 10.01 19.997 10.008C14.545 10.008 10.11 14.442 10.108 19.892C10.107 22.117 10.759 23.783 11.854 25.526L10.855 29.174L14.597 28.193ZM25.984 22.729C25.91 22.605 25.712 22.531 25.414 22.382C25.117 22.233 23.656 21.514 23.383 21.415C23.111 21.316 22.913 21.266 22.714 21.564C22.516 21.861 21.946 22.531 21.773 22.729C21.6 22.927 21.426 22.952 21.129 22.803C20.832 22.654 19.874 22.341 18.739 21.328C17.856 20.54 17.259 19.567 17.086 19.269C16.913 18.972 17.068 18.811 17.216 18.663C17.35 18.53 17.513 18.316 17.662 18.142C17.813 17.97 17.862 17.846 17.962 17.647C18.061 17.449 18.012 17.275 17.937 17.126C17.862 16.978 17.268 15.515 17.021 14.92C16.779 14.341 16.534 14.419 16.352 14.41L15.782 14.4C15.584 14.4 15.262 14.474 14.99 14.772C14.718 15.07 13.95 15.788 13.95 17.251C13.95 18.714 15.015 20.127 15.163 20.325C15.312 20.523 17.258 23.525 20.239 24.812C20.948 25.118 21.502 25.301 21.933 25.438C22.645 25.664 23.293 25.632 23.805 25.556C24.376 25.471 25.563 24.837 25.811 24.143C26.059 23.448 26.059 22.853 25.984 22.729Z"
                    fill="#ECECEC"
                    style={{ fill: '#A5A5A5', fillOpacity: 1 }}
                  />
                </g>
                <defs>
                  <clipPath id="clip0_831_1487">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      style={{ fill: 'white', fillOpacity: 1 }}
                      transform="translate(8 8)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
        <div className={styles.policy}>
          <a href="/app/termsService" target="_blank">
            <Trans>Terms of Service</Trans>
          </a>
          <a href="/app/privacyPolicy" target="_blank">
            <Trans>Privacy Policy</Trans>
          </a>
          <a href="/app/cookiePolicy" target="_blank">
            <Trans>Cookie Policy</Trans>
          </a>
        </div>
        <div className={styles.copyright}>© 2025 All rights reserved</div>
      </div>
    </div>
  )
}

export default Footer
